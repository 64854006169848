import React, { useMemo } from 'react'
import Head from 'next/head'
import Script from 'next/script'
import { prismicText } from './CMSText'
import { useRouter } from 'next/router'

type TProps = {
  datas: any
}

const SharedHead = ({ datas }: TProps) => {
  const favicon = datas ? datas.data.favicon : null
  const router = useRouter()
  const pageRoute = useMemo(() => {
    const basePath =
      'https://' +
      (process.env.PUBLIC_DOMAIN ||
        (typeof window !== 'undefined' ? window.location.host : undefined))
    if (router.isReady) {
      return basePath + router.asPath
    }
    return basePath + router.pathname
  }, [router.asPath, router.pathname, router.isReady])

  return (
    <Head>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1"
      />
      {datas?.data?.meta_url && (
        <meta property="og:url" content={prismicText(datas?.data?.meta_url)} />
      )}
      {datas?.data?.meta_image?.url && (
        <meta
          property="og:image"
          content={datas?.data?.meta_image?.url}
          name="image"
        />
      )}

      <link rel="canonical" href={pageRoute} key="canonical" />
      {/* Minimum favicon config TODO: https://stackoverflow.com/questions/48956465/favicon-standard-2020-svg-ico-png-and-dimensions */}
      {favicon?.url && (
        <>
          <link rel="apple-touch-icon" sizes="180x180" href={favicon.url} />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16 32x32 48x48"
            href={favicon['small'].url}
          />
        </>
      )}
      <Script src="https://unpkg.com/swiper@6.4.8/swiper-bundle.min.js" />
    </Head>
  )
}

export default SharedHead
